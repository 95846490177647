import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Unstable_Grid2';
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { addUser } from '../api/users'
import { Alert } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [usStates, setUsStates] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [message, setMessage] = useState('Please provide the required information highlighted in red below.');
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [validationState, setValidationState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    zipcode: false,
    usStates: false,
  });
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$')
  const validFirstName = new RegExp('^[a-zA-z]*$')
  const validLastName = new RegExp('^[a-zA-Z\'. -]+$')
  const validZipCode = new RegExp('^\\d{5}(-\\d{4})?$')
  const isValidated = () => {
    let valid = true
    for (const property in validationState) {
      if (!validationState[property]) {
        setMessage(`Please provide valid ${property}.`)
        setShowMessage(true)
        valid = false
        break
      }
    }
    return valid
  }
  let navigate = useNavigate();
  const clearForm = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setZipcode('')
    setUsStates('')
    setIsSubmitted(false)
    setShowMessage(false)
  }
  const handleSubmit = async () => {
    setIsSubmitted(true)
    setLoading(true)
    if (isValidated()) {
      const payload = {
        // name: {
        //   firstName: firstName,
        //   lastName: lastName,
        //   email: email,
        //   zipCode: zipcode,
        //   state: usStates
        // },
        firstName: firstName,
        lastName: lastName,
        email: email,
        zipCode: zipcode,
        state: usStates
      }
      try {
        await addUser(payload)
        navigate("/thankyou");
      } catch (e) {
        console.log(e.toString())
        navigate("/thankyou");
      }
    } else {
      setShowMessage(true)
    }
    setLoading(false)
  }
  return (
    <>
      <Box sx={{ textAlign: 'center', padding: '50px', marginY: '70px' }}>
        <Typography variant="h3" gutterBottom>
          Add User
        </Typography>
        <Grid container>
          <Grid item xsOffset={0} mdOffset={2} xs={12} md={8}>
            <Paper
              elevation={6}
              style={{
                padding: 20,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  {showMessage && (<Alert severity="error">
                    {message}
                  </Alert>)}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={isSubmitted && (firstName === '' || !validationState.firstName)}
                    fullWidth
                    id="firstName"
                    label="First name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setValidationState({
                        ...validationState,
                        firstName: !!e.target.value && validFirstName.test(firstName),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    error={isSubmitted && (lastName === '' || !validationState.lastName)}
                    fullWidth
                    id="lastName"
                    label="Last name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setValidationState({
                        ...validationState,
                        lastName: !!e.target.value && validLastName.test(e.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={isSubmitted && (email === '' || !validationState.email)}
                    fullWidth
                    id="email"
                    label="Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setValidationState({
                        ...validationState,
                        email: e.target.value !== '' && validEmail.test(email),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={isSubmitted && (zipcode === '' || !validationState.zipcode)}
                    fullWidth
                    id="zipcode"
                    label="Zip code"
                    value={zipcode}
                    onChange={(e) => {
                      setZipcode(e.target.value);
                      setValidationState({
                        ...validationState,
                        zipcode: !!e.target.value && validZipCode.test(e.target.value),
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="usStates">US State</InputLabel>
                    <Select
                      error={isSubmitted && (usStates === '')}
                      labelId="select-label"
                      id="usStates"
                      value={usStates}
                      label="US State"
                      onChange={(e) => {
                        setUsStates(e.target.value);
                        setValidationState({
                          ...validationState,
                          usStates: !!e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="AL">Alabama</MenuItem>
                      <MenuItem value="AK">Alaska</MenuItem>
                      <MenuItem value="AS">American Samoa</MenuItem>
                      <MenuItem value="AZ">Arizona</MenuItem>
                      <MenuItem value="AR">Arkansas</MenuItem>
                      <MenuItem value="UM-81">Baker Island</MenuItem>
                      <MenuItem value="CA">California</MenuItem>
                      <MenuItem value="CO">Colorado</MenuItem>
                      <MenuItem value="CT">Connecticut</MenuItem>
                      <MenuItem value="DE">Delaware</MenuItem>
                      <MenuItem value="DC">District of Columbia</MenuItem>
                      <MenuItem value="FL">Florida</MenuItem>
                      <MenuItem value="GA">Georgia</MenuItem>
                      <MenuItem value="GU">Guam</MenuItem>
                      <MenuItem value="HI">Hawaii</MenuItem>
                      <MenuItem value="UM-84">Howland Island</MenuItem>
                      <MenuItem value="ID">Idaho</MenuItem>
                      <MenuItem value="IL">Illinois</MenuItem>
                      <MenuItem value="IN">Indiana</MenuItem>
                      <MenuItem value="IA">Iowa</MenuItem>
                      <MenuItem value="UM-86">Jarvis Island</MenuItem>
                      <MenuItem value="UM-67">Johnston Atoll</MenuItem>
                      <MenuItem value="KS">Kansas</MenuItem>
                      <MenuItem value="KY">Kentucky</MenuItem>
                      <MenuItem value="UM-89">Kingman Reef</MenuItem>
                      <MenuItem value="LA">Louisiana</MenuItem>
                      <MenuItem value="ME">Maine</MenuItem>
                      <MenuItem value="MD">Maryland</MenuItem>
                      <MenuItem value="MA">Massachusetts</MenuItem>
                      <MenuItem value="MI">Michigan</MenuItem>
                      <MenuItem value="UM-71">Midway Atoll</MenuItem>
                      <MenuItem value="MN">Minnesota</MenuItem>
                      <MenuItem value="MS">Mississippi</MenuItem>
                      <MenuItem value="MO">Missouri</MenuItem>
                      <MenuItem value="MT">Montana</MenuItem>
                      <MenuItem value="UM-76">Navassa Island</MenuItem>
                      <MenuItem value="NE">Nebraska</MenuItem>
                      <MenuItem value="NV">Nevada</MenuItem>
                      <MenuItem value="NH">New Hampshire</MenuItem>
                      <MenuItem value="NJ">New Jersey</MenuItem>
                      <MenuItem value="NM">New Mexico</MenuItem>
                      <MenuItem value="NY">New York</MenuItem>
                      <MenuItem value="NC">North Carolina</MenuItem>
                      <MenuItem value="ND">North Dakota</MenuItem>
                      <MenuItem value="MP">Northern Mariana Islands</MenuItem>
                      <MenuItem value="OH">Ohio</MenuItem>
                      <MenuItem value="OK">Oklahoma</MenuItem>
                      <MenuItem value="OR">Oregon</MenuItem>
                      <MenuItem value="UM-95">Palmyra Atoll</MenuItem>
                      <MenuItem value="PA">Pennsylvania</MenuItem>
                      <MenuItem value="PR">Puerto Rico</MenuItem>
                      <MenuItem value="RI">Rhode Island</MenuItem>
                      <MenuItem value="SC">South Carolina</MenuItem>
                      <MenuItem value="SD">South Dakota</MenuItem>
                      <MenuItem value="TN">Tennessee</MenuItem>
                      <MenuItem value="TX">Texas</MenuItem>
                      <MenuItem value="UM">United States Minor Outlying Islands</MenuItem>
                      <MenuItem value="VI">United States Virgin Islands</MenuItem>
                      <MenuItem value="UT">Utah</MenuItem>
                      <MenuItem value="VT">Vermont</MenuItem>
                      <MenuItem value="VA">Virginia</MenuItem>
                      <MenuItem value="UM-79">Wake Island</MenuItem>
                      <MenuItem value="WA">Washington</MenuItem>
                      <MenuItem value="WV">West Virginia</MenuItem>
                      <MenuItem value="WI">Wisconsin</MenuItem>
                      <MenuItem value="WY">Wyoming</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} >
                  <LoadingButton  disabled={loading} loading={loading} onClick={handleSubmit} variant="contained" color="primary">
                    Submit
                  </LoadingButton>
                  <Button variant="outlined" color="primary" disabled={loading} id="" style={{marginLeft: '10px'}} onClick={clearForm}>
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactForm;
