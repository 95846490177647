import React from 'react'
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

const ThankYouPage = () => {
  return (

    <>
      <Box sx={{ textAlign: 'center', padding: '150px', marginTop: '100px', height: '150px' }}>
        <Typography variant="h5" gutterBottom>
          Thank you for your submission.
        </Typography>
      </Box>
    </>
  )
}

export default ThankYouPage