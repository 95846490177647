import "./App.css";
import ContactForm from "./components/ContactForm";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ThankYouPage from "./components/ThankYouPage";
import AppFooter from "./components/AppFooter";
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ResponsiveAppBar />
      </div>
      <Routes>
        <Route exact path="/thankyou" element={<ThankYouPage />} />
        <Route exact path="/" element={<ContactForm />} />
      </Routes>
      <AppFooter />
    </BrowserRouter>
  );
}

export default App;
